<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h3 class="font-weight-bold text-center primary--text">
          Applicant Details
        </h3>
      </v-col>

      <!-- <v-col cols="12">
        <v-img
          :src="`https://kinplusgroup.com/nodejs/uploads/${dform.passport}`"
          height="150"
          width="150"
          class="mx-auto"
          contain
        >
          <template #placeholder>
            <v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader>
          </template>
        </v-img>
      </v-col> -->
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="item.program"
          :items="programs"
          label="Program: "
          outlined
          dense
          required
          @change="saveChanges(item, 'program')"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
          v-model="item.period"
          :items="periods"
          label="Period: "
          outlined
          dense
          required
          @change="saveChanges(item, 'period')"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="item.email"
          label="Email: "
          @change="saveChanges(item, 'email')"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Religion: </span>
        {{ item.religion }}
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Date of Birth: </span>
        {{ item.dob }} :
        {{ new Date().getFullYear() - new Date(item.dob).getFullYear() }} yrs
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Qualification: </span>
        {{ item.qualification }}
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Discipline: </span>
        {{ item.discipline }}
      </v-col>
      <!-- <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Curriculum Vitae: </span>
        <v-btn
          small
          color="primary"
          :href="`/uploads/${item.cv}`"
          target="_blank"
          >View CV</v-btn
        >
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Level of Expertise : </span>
        {{ item.expertise }}
      </v-col> -->
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Laptop: </span>
        {{ item.laptop }}
      </v-col>
      <!-- <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Available: </span>
        {{ item.available }}
      </v-col> -->
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Bio: </span>
        {{ item.bio }}
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span class="font-weight-bold">Training Center: </span>
        {{ item.trainingCenter }}
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from "@/mixins";
import { mapState } from "vuex";

export default {
  name: "InternDetails",
  mixins: [snackMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    saveChanges: Function,
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
    };
  },
  computed: {
    ...mapState(["periods", "programs"]),
  },
};
</script>
